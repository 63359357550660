<template>
  <div>
    <b-alert
      :show="countdown"
      dismissible
      variant="success"
      @dismissed="countdown = 0"
      @dismiss-count-down="close_alert"
      class="show_alert"
    >
      {{ dismessage }}
    </b-alert>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tab_idx" @activate-tab="change_tab">
              <b-tab v-if="hasUtilityAuth('VIPSettingEdit')">
                <template v-slot:title>{{ $t("Action Setting") }} </template>
                <b-card-body>
                  <b-form v-if="vip_setting">
                    <b-row>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" :label="$t('VIP Solution')" label-for="input-vipsolution">
                          <b-form-select id="input-vipsolution" v-model="vip_setting.VIPSolution" class="mt-2" size="sm">
                            <b-form-select-option value="VIP">VIP</b-form-select-option>
                            <b-form-select-option value="Snappli">Snaplii Reload Card</b-form-select-option>
                            <b-form-select-option value="SnappliCb">Snaplii Dividend</b-form-select-option>
                            <b-form-select-option value="OW">Orange Wallet</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="9">
                      </b-col>
                    </b-row>
                    <b-row  v-if="vip_setting.VIPSolution == 'VIP'">
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" :label="$t('VIP Password')" label-for="input-vipassword">
                          <b-form-checkbox
                            class="mt-2"
                            id="input-vipassword" 
                            v-model="vip_setting.VIPPassword"
                            value="Enable"
                            unchecked-value="Disable"
                            switch
                          >
                            <span v-if="vip_setting.VIPPassword == 'Disable'">
                              {{ $t("Off") }}
                            </span>
                            <span v-else>
                              {{ $t("On") }}
                            </span>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" :label="$t('Total to Point Rate')" label-for="input-vippointrate">
                          <b-form-input id="input-vippointrate" v-model="vip_setting.VIPPointRate" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" :label="$t('Point To Credit Rate')" label-for="input-vipconvertrate">
                          <b-form-input id="input-vipconvertrate" v-model="vip_setting.VIPConvertRate" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="Card" label-for="input-vip-card">
                          <b-form-checkbox
                            class="mt-2"
                            id="input-vip-card" 
                            v-model="vip_setting.VIPCardEnable"
                            value="Enable"
                            unchecked-value="Disable"
                            switch
                          >
                            <span v-if="vip_setting.VIPCardEnable == 'Disable'">
                              {{ $t("Disable") }}
                            </span>
                            <span v-else>
                              {{ $t("Enable") }}
                            </span>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col>
                      </b-col>
                    </b-row>
                    <b-row  v-else-if="vip_setting.VIPSolution == 'OW'">
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="URL" label-for="input-ow-url">
                          <b-form-input id="input-ow-url" v-model="vip_setting.OwURL" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="StoreID" label-for="input-ow-storeid">
                          <b-form-input id="input-ow-storeid" v-model="vip_setting.OwStoreId" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="Token" label-for="input-ow-token">
                          <b-form-input id="input-ow-token" v-model="vip_setting.OwToken" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="Store No" label-for="input-vipstoreno">
                          <b-form-input id="input-vipstoreno" v-model="vip_setting.SnappliStoreNo" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="Merchant ID" label-for="input-vipmerchantid">
                          <b-form-input id="input-vipmerchantid" v-model="vip_setting.SnappliMerchantID" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="APP ID" label-for="input-vipappid">
                          <b-form-input id="input-vipappid" v-model="vip_setting.SnappliAppID" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label-cols="6" label-align-sm="right" label="Hash Key" label-for="input-hashkey">
                          <b-form-input id="input-hashkey" v-model="vip_setting.SnappliHashKey" class="mt-2" size="sm"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-lg-center mt-3">
                      <b-col class="text-center" lg="4">
                        <el-button type="primary" @click="update_setting">{{ $t("Submit") }}</el-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-tab>

              <b-tab lazy>
                <template v-slot:title>{{ $t("Type") }}</template>
                <div>
                  <b-form>
                    <b-row>
                      <b-col>
                        <el-button
                          class="button-new-tag mt-3"
                          type="primary"
                          size="small"
                          @click="handleAdd"
                          v-acl:VIPSettingEdit.enable.show
                          >+ {{ $t("New") }}</el-button
                        >
                      </b-col>
                    </b-row>
                    <div v-if="vip_type">
                      <el-table
                        stripe
                        class="table-responsive table"
                        header-row-class-name="thead-light"
                        :data="vip_type"
                        @row-click="row_clicked_event"
                      >
                        <el-table-column :label="$t('Name')" prop="Name"></el-table-column>
                        <el-table-column :label="$t('Discount Rate')" prop="Discount"></el-table-column>
                        <el-table-column :label="$t('Price Level')" prop="Price"></el-table-column>
                        <el-table-column :label="$t('Max Discount Amount')" prop="Amount"></el-table-column>
                        <el-table-column :label="$t('Product')" prop="Amount">
                          <template v-slot="{ row }">
                            {{ get_product_name(row) }}
                          </template>
                        </el-table-column>
                        <el-table-column label="" v-if="hasUtilityAuth('VIPSettingEdit')">
                          <template v-slot="{ row }">
                            <i @click="delete_type(row.IDn)" class="el-icon-remove" style="color: #bd4545" v-acl:VIPSettingEdit.enable.show></i>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </b-form>
                </div>
              </b-tab>

              <b-tab lazy :disabled="vip_setting && vip_setting.VIPSolution=='VIP'" v-if="hasUtilityAuth('VIPSettingEdit')">
                <template v-slot:title>Snappli</template>
                <div>
                  <div>{{ $t("VIP Sales") }}</div>
                  <el-table
                    stripe
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="vip_products"
                    @row-click="vip_product_row_clicked"
                  >
                    <el-table-column :label="$t('Name')" prop="Name1"></el-table-column>
                    <el-table-column :label="$t('Sale Price')" prop="APrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.APrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Price')" prop="BPrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.BPrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Points')" prop="CPrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.CPrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Product Name')" prop="ShortName"></el-table-column>
                    <el-table-column :label="$t('Type')" prop="Brand"></el-table-column>
                    <el-table-column label="">
                      <template v-slot="{ row }">
                        <i v-if="row.Status=='Active'" @click="set_product_status(row.IDn, 'Deactive')" class="el-icon-remove" style="color: #bd4545"></i>
                        <i v-else @click="set_product_status(row.IDn, 'Active')" class="el-icon-success" style="color: #67C23A"></i>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div>{{ $t("VIP Reload") }}</div>
                  <el-table
                    stripe
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="vip_reloads"
                    @row-click="vip_reload_row_clicked"
                  >
                    <el-table-column :label="$t('Name')" prop="Name1"></el-table-column>
                    <el-table-column :label="$t('Sale Price')" prop="APrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.APrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Price')" prop="BPrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.BPrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Points')" prop="CPrice">
                      <template v-slot="{ row }">
                        {{ parseFloat(row.CPrice).toFixed(2) }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Product Name')" prop="ShortName"></el-table-column>
                    <el-table-column :label="$t('Type')" prop="Brand"></el-table-column>
                    <el-table-column label="">
                      <template v-slot="{ row }">
                        <i v-if="row.Status=='Active'" @click="set_product_status(row.IDn, 'Deactive')" class="el-icon-remove" style="color: #bd4545"></i>
                        <i v-else @click="set_product_status(row.IDn, 'Active')" class="el-icon-success" style="color: #67C23A"></i>
                      </template>
                    </el-table-column>
                  </el-table>
                  <b-form>
                    <b-row>
                      <b-col>
                        <el-button
                          class="button-new-tag mt-3"
                          type="primary"
                          size="small"
                          @click="get_snappli_products(1)"
                          >Get VIP Products</el-button
                        >
                        <el-button
                          class="button-new-tag mt-3"
                          type="primary"
                          size="small"
                          @click="get_snappli_products(2)"
                          >Get Reload Products</el-button
                        >
                      </b-col>
                    </b-row>
                    <div v-if="snappli_products">
                      <el-card class="box-card" v-for="prod in snappli_products" :key="prod.card_prd_cod">
                        <el-row :gutter="10">
                          <el-col :span="2" class="text-right">Code</el-col>
                          <el-col :span="4">{{ prod.card_prd_cod }}</el-col>
                          <el-col :span="2" class="text-right">Name</el-col>
                          <el-col :span="4">{{ prod.card_nm }}</el-col>
                          <el-col :span="2" class="text-right">Min Amt</el-col>
                          <el-col :span="4">{{ prod.min_amt }}</el-col>
                          <el-col :span="2" class="text-right">Desc</el-col>
                          <el-col :span="4">{{ prod.card_desc }}</el-col>
                        </el-row>
                        <el-row :gutter="10" class="snappli_prod">
                          <el-table
                            stripe
                            class="table-responsive table"
                            header-row-class-name="thead-light"
                            :data="prod.prod_list"
                            @row-click="vip_reload_row_clicked"
                          >
                            <el-table-column label="ID" prop="goods_id"></el-table-column>
                            <el-table-column :label="$t('Name')" prop="goods_nm"></el-table-column>
                            <el-table-column :label="$t('Sale Price')" prop="act_prc">
                              <template v-slot="{ row }">
                                {{ parseFloat(row.pay_amount).toFixed(2) }}
                              </template>
                            </el-table-column>
                            <el-table-column :label="$t('Price')" prop="goods_prc">
                              <template v-slot="{ row }">
                                {{ parseFloat(row.full_amount).toFixed(2) }}
                              </template>
                            </el-table-column>
                            <el-table-column :label="$t('Type')" prop="goods_typ">
                              <template v-slot="{ row }">
                                <div v-if="row.goods_typ==1">Open</div>
                                <div v-if="row.goods_typ==2">Reload</div>
                              </template>
                            </el-table-column>
                            <el-table-column label="">
                              <template v-slot="{ row }">
                                <b-button
                                  variant="outline-dark"
                                  size="sm"
                                  @click="update_products($event, prod.card_prd_cod, row)"
                                  >{{ row.SnappliBtnName }}
                                  </b-button>
                              </template>
                            </el-table-column>
                          </el-table>
                        </el-row>
                      </el-card>
                    </div>
                  </b-form>
                </div>
              </b-tab>

              <b-tab lazy>
                <template v-slot:title>{{ $t("Group setting") }}</template>
                <div class="vip_group_setting_table">
                  <b-form>
                    <div>
                      <el-table
                        stripe
                        class="table-responsive table"
                        header-row-class-name="thead-light"
                        :data="vip_groups"
                      >
                        <el-table-column min-width="10%" :label="$t('Group Name')" prop="VIPGroupName"></el-table-column>
                        <el-table-column min-width="70%" :label="$t('Store')">
                          <template v-slot="{ row }">
                            <el-checkbox
                              v-for="store,idx in stores"
                              :key="idx"
                              v-model="store.VIPGroupIDn"
                              @change="changeVipGroup(store)"
                              :true-label='row.IDn'
                              :false-label='0'
                              :checked='store.VIPGroupIDn == row.IDn'
                            > {{ store.LocationName }} </el-checkbox>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="10%" v-if="hasUtilityAuth('VIPSettingEdit')">
                          <template slot="header">
                            <span class="btn-inner--icon" @click="add_vipgroup">
                              <i class="ni ni-fat-add icon-add"></i>
                            </span>
                          </template>
                          <template slot-scope="scope">
                            <span class="btn-inner--icon" @click="remove_vipgroup(scope.row)">
                              <i class="ni ni-fat-remove icon-remove"></i>
                            </span>
                            <span class="btn-inner--icon" @click="edit_vipgroup(scope.row)">
                              <i class="ni ni-settings-gear-65 icon-edit"></i>
                            </span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </b-form>
                </div>
              </b-tab>

            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <el-dialog :title="'VIP' + $t('Type')" :visible.sync="dialogFormVisible">
        <div v-if="cur_type">
          <base-input :label="$t('Name')">
            <b-input
              placeholder="Name"
              class="form-control"
              v-model="cur_type.Name"
            />
          </base-input>
          <base-input :label="$t('Discount Rate')">
            <b-input
              placeholder="Discount Rate"
              class="form-control"
              v-model="cur_type.Discount"
            />
          </base-input>
          <base-input :label="$t('Price Level')">
            <select class="form-control" v-model="cur_type.Price">
              <option value="">None</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
              <option value="G">G</option>
              <option value="H">H</option>
              <option value="I">I</option>
              <option value="J">J</option>
            </select>
          </base-input>
          <base-input :label="$t('Max Discount Amount')">
            <b-input
              placeholder="Max Discount Amount"
              class="form-control"
              v-model="cur_type.Amount"
            />
          </base-input>
          <base-input :label="$t('Product')">
            <select class="form-control" v-model="cur_type.ProductIDn">
              <option :value="0"> N/A </option>
              <option v-for="prod in vip_products" :key="prod.IDn" :value="prod.IDn">{{ prod.Name1 }}</option>
            </select>
          </base-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="update_type">{{ act }}</el-button>
        </span>
      </el-dialog>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>

	  <b-modal ref="vip_group_modal" :title="$t('VIP Group')" id="vip_group_modal" size="l">
      <div v-if='cur_vipgroup'>
        <el-row :gutter="10">
          <el-col style="margin-left: 5px; margin-bottom: 5px">
            {{ $t('Please input the VIP Group Name') }} :
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="20">
            <el-input v-model="cur_vipgroup.VIPGroupName">
            </el-input>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="confirm_vipgroup">
          {{ $t('Confirm') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_vipgroup_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    </b-container>
  </div>
</template>

<style>
.content {
  padding-top: 80px;
}
.form-group {
  margin-bottom: 1rem;
}
.search-button {
  margin-top: 2rem;
}
.show_alert {
  position: absolute;
  top: 4rem;
  width: 100%;
  z-index: 100;
}
.el-tag {
  margin-left: 1px;
  margin-right: 1px;
}
.discountspan {
  margin-right: 5px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.vip_group_setting_table {
  margin-top: 10px;
}
.vip_group_setting_table .btn-inner--icon .ni:hover {
  cursor: pointer;
}
.vip_group_setting_table .btn-inner--icon .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
  font-size: 1.8rem;
}
.vip_group_setting_table .btn-inner--icon .icon-remove {
  color: red;
  font-size: 1.8rem;
  top: 4px;
}
.vip_group_setting_table .btn-inner--icon .icon-edit {
  font-size: 1.4rem;
  margin-left: 20px;
}
</style>
<script>
import { Table, TableColumn, Button, Radio, Tag, Dialog, Card, Row, Col, Checkbox } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [Tag.name]: Tag,
    [Dialog.name]: Dialog,
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Checkbox.name]: Checkbox,
 },
  data() {
    return {
      tab_idx: 0,
      act: "Add",
      cur_type: null,
      new_type: {
        IDn: 0,
        Name: "",
        Discount: 0,
        Price: "",
        Amount: "",
        ProductIDn: 0,
      },

      dialogFormVisible: false,

      dismessage: '',
      countdown: 0,

      vip_type: null,
      vip_setting: null,
      vip_products: [],
      vip_reloads: [],
      vip_groups: [],
      stores: [],
      cur_vipgroup: null,

      snappli_products: null,
      DepartmentIDn: null,
      redeemtype: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    change_tab(nidx, oidx, ev) {
      // console.log("change_tab",nidx, oidx, ev);
    },
    vip_product_row_clicked(row, event, column) {
      // console.log("vip_product_row_clicked", row, event, column);
    },
    vip_reload_row_clicked(row, event, column) {
      // console.log("vip_reload_row_clicked", row, event, column);
    },
    set_product_status(IDn, status) {

    },
    // Lisa add --- start ---
    update_products(event, code, row){

      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      // set the value for check
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      // set the values for PKProduct update
      bodyFormData.append("DepartmentIDn", this.DepartmentIDn);
      bodyFormData.append("CategoryIDn", row.CategoryIDn);
      bodyFormData.append("ShortName", code);
      bodyFormData.append("SKU", row.goods_id);
      bodyFormData.append("Name", row.goods_nm);
      bodyFormData.append("APrice", parseFloat(row.pay_amount).toFixed(2));
      bodyFormData.append("BPrice", parseFloat(row.full_amount).toFixed(2));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/vip_setting/updateproduct",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t('Data Updated Successfully');
              that.countdown = 3;
              
              row.SnappliBtnName = 'Update'; 

            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // Lisa add --- end ---
    get_snappli_products(type) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("type", type);
      
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/POS/vip_setting/snappli_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.snappli_products = rt.data.snappli_products;
              // start
              that.DepartmentIDn = rt.data.DepartmentIDn;
              // end
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, event, column);
      if (!hasUtilityAuth('VIPSettingEdit')) return;
      event.preventDefault();
      if (column.label == "") {
        return;
      }
      this.cur_type = JSON.parse(JSON.stringify(row));
      this.act = "Update";
      this.dialogFormVisible = true;
    },
    changeVipGroup(store) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", store.LocationID);
      bodyFormData.append("VIPGroupIDn", store.VIPGroupIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip_setting/change_vip_group",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_groups = rt.data.vip_groups;
              that.stores = rt.data.stores;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    add_vipgroup() {
      this.cur_vipgroup = {
        IDn: 0,
        VIPGroupName: "",
      };
      this.$refs["vip_group_modal"].show();
    },
    remove_vipgroup(vipgroup) {
      var alertMsg = "Are you sure to delete the group?";
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("vipgroup", JSON.stringify(vipgroup));
      
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/POS/Vip_setting/delete_vipgroup",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_groups = rt.data.vip_groups;
              that.stores = rt.data.stores;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    edit_vipgroup(vipgroup) {
      this.cur_vipgroup = JSON.parse(JSON.stringify(vipgroup));
      this.$refs["vip_group_modal"].show();
    },
    confirm_vipgroup() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("vipgroup", JSON.stringify(this.cur_vipgroup));
      
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/POS/Vip_setting/update_vipgroup",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_groups = rt.data.vip_groups;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
      
      this.$refs["vip_group_modal"].hide();
    },
    close_vipgroup_model() {
      this.$refs["vip_group_modal"].hide();
    },
    delete_type(idn) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "type");
      bodyFormData.append("act", "Delete");
      bodyFormData.append("IDn", idn);
      
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/POS/vip_setting/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_type = rt.data.vip_type;
              that.dismessage = that.$t('Type Data Delete Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_product_name(row) {
      if (this.vip_products.length > 0) {
        for (var i=0; i<this.vip_products.length; i++) {
          if (this.vip_products[i].IDn == row.ProductIDn) {
            return this.vip_products[i].Name1;
          }
        }
      }
      return "-";
    },
    update_type() {
      this.dialogFormVisible = false;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "type");
      bodyFormData.append("act", this.act);
      bodyFormData.append("data", JSON.stringify(this.cur_type));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/vip_setting/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_type = rt.data.vip_type;
              that.dismessage = that.$t('Type Data Added Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    handleAdd() {
      this.cur_type = JSON.parse(JSON.stringify(this.new_type));;
      this.act = "Add";
      this.dialogFormVisible = true;
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    async update_setting(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "setting");
      bodyFormData.append("VIPSolution", this.vip_setting.VIPSolution);
      if (this.vip_setting.VIPSolution == 'VIP') {
        bodyFormData.append('VIPPassword', this.vip_setting.VIPPassword);
        bodyFormData.append('VIPPointRate', this.vip_setting.VIPPointRate);
        bodyFormData.append('VIPConvertRate', this.vip_setting.VIPConvertRate);
        bodyFormData.append('VIPCardEnable', this.vip_setting.VIPCardEnable);
      } else if (this.vip_setting.VIPSolution == 'OW') {
        bodyFormData.append('OwURL', this.vip_setting.OwURL);
        bodyFormData.append('OwStoreId', this.vip_setting.OwStoreId);
        bodyFormData.append('OwToken', this.vip_setting.OwToken);
      } else if ((this.vip_setting.VIPSolution == 'Snappli') || (this.vip_setting.VIPSolution == 'SnappliCb')) {  // Snappli / SnappliCb
        bodyFormData.append('SnappliStoreNo', this.vip_setting.SnappliStoreNo);
        bodyFormData.append('SnappliMerchantID', this.vip_setting.SnappliMerchantID);
        bodyFormData.append('SnappliAppID', this.vip_setting.SnappliAppID);
        bodyFormData.append('SnappliHashKey', this.vip_setting.SnappliHashKey);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/vip_setting/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.vip_setting = rt.data.vip_setting;
              that.dismessage = that.$t('Data Updated Successfully');
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              if (rt.message == 'All VIP credit should be set to zero before enabling VIP card'
                  || rt.message == 'No card should be bound to customer before disabling VIP card') {
                alert(that.$t(rt.message));
              } else {
                alert(rt.message);
              }
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_data() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_LocationID);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip_setting",
        data: bodyFormData,
      })
        .then(function (response) {
          // console.log(response);
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.is = rt.data.vip_type;
              that.vip_type = rt.data.vip_type;
              that.vip_setting = rt.data.vip_setting;
              that.vip_products = rt.data.vip_products;
              that.vip_reloads = rt.data.vip_reloads;
              that.vip_groups = rt.data.vip_groups;
              that.stores = rt.data.stores;
              that.redeemtype = rt.data.redeemtype;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>